<template>
  <div class="d-flex flex-column flex-root" v-loading="loading">
    <div
      class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white login-forgot-on"
      id="kt_login"
    >
      <!--begin::Aside-->
      <div
        class="login-aside d-flex flex-column flex-row-auto"
        style="background-color: #F2C98A;"
      >
        <div
          class="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center"
          :style="{ backgroundImage: `url(${backgroundImage})`, backgroundPositionY: 'center' }"
        ></div>
      </div>
      <!--begin::Aside-->
      <!--begin::Content-->
      <div
        class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
      >
        <div class="d-flex flex-column-fluid flex-center">
          <!--begin::Forgot-->
          <div v-if="isSendMail == false" class="login-form login-forgot">
            <!--begin::Form-->
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_forgot_form"
              ref="kt_login_forgot_form"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  パスワード再設定
                </h3>
                <p class="text-muted font-weight-bold font-size-h4">
                  会員登録いただいた際のメールアドレスを入力し、「送信」のボタンをクリックしてください。パスワード再設定用URLを送信します。
                </p>
              </div>
              <div class="form-group">
                <label>
                  メールアドレス
                </label>
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="email"
                  name="email"
                  ref="remail"
                  autocomplete="off"
                />
              </div>
              <div class="form-group d-flex flex-wrap pb-lg-0">
                <button
                  id="kt_login_forgot_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                >
                  送信
                </button>
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                  @click="goToLogin()"
                >
                  戻る
                </button>
              </div>
            </form>
          </div>
          <!--end::Forgot-->
          <div v-else class="tg_popup popup_alert popshow">
                <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  メール送信済み
                </h3>
                <div class="content_popup">
                    <div class="textwidget">
                        <p>パスワード再設定ページへのURLを記載したメールを送信しましました。</p>
                        <p>メールが届かない場合、迷惑メールフォルダーに届いていないかご確認ください。</p>
                    </div>
                <button
                  id="kt_login_forgot_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                  @click="goToLogin()"
                >
                  戻る
                </button>
                </div>
            </div>
        </div>
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";
import { mapGetters, mapState } from "vuex";
import { LOGOUT, SEND_FORGOT_PASSWORD } from "@/core/services/store/auth.module";
import Swal from "sweetalert2";

import { STATUS_CODE } from "@/core/config/constant";

/* Status code */
const { AUTHENTICATE, ERROR_SERVER, SUCCESS } = STATUS_CODE

export default {
  name: "ForgotPassword",
  data() {
    return {
      state: "forgot",
      isSendMail: false,
      loading: false,
    };
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    ...mapGetters(["currentUser"]),

    backgroundImage() {
      return (
        process.env.BASE_URL + "media/logos/rogo.png"
      );
    }
  },
  mounted() {
    const forgot_form = KTUtil.getById("kt_login_forgot_form");

    this.fv2 = formValidation(forgot_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: this.$t('required', {field: "メールアドレス"})
            },
            emailAddress: {
              message: this.$t('validateEmail'),
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });

    this.fv2.on("core.form.valid", async () => {
        const email = this.$refs.remail.value;

         // clear existing errors
        this.$store.dispatch(LOGOUT);

        this.loading = true;
        const data = await this.$store.dispatch(SEND_FORGOT_PASSWORD, {email})
        this.loading = false;
       if (data.code == SUCCESS) {
           this.isSendMail = true;
       } else {
           if (data.data.email) {
            this.notifyError(data.data.email[0])
           }
           if (data.code == ERROR_SERVER) {
            this.notifyError(data.message)
           }
       }
    });
  },

  methods: {
    goToLogin() {
      this.$router.push({ name: "login" });
    }
  }
};
</script>
